.sub-category-card {
    border: 2px solid #0099ff;
    padding: 0;
    border-radius: 8px;
    cursor: pointer;

    /* width: 300px;
    height: 300px; */
    overflow: hidden;
    margin: 0 auto;
}

.sub-category-card:hover {
    border: 2px solid #0099ff;
    color: white;
    background-color: #0099ff;
    padding: 0;
    border-radius: 8px;
    cursor: pointer;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.sub-category-card:hover > div img {
    width: 100%;
    transition: 0.5s all ease-in-out;
}

.sub-category-card:hover > div img {
    transform: scale(1.1);
}