.blog-card {
    border: 1px solid #b5b5b5;
    background-color: white;
    border-radius: 8px;
    display: grid;
    cursor: pointer;

    overflow: hidden;
    margin: 0 auto;
}

.blog-card:hover {
    border: 1px solid #b5b5b5;
    background-color: white;
    border-radius: 8px;
    display: grid;
    box-shadow: 1px 1px 15px 1px rgb(101, 101, 101);
    cursor: pointer;
}

.blog-subtitle {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    line-height: 19px;
    max-height: 57px;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    /* height: 40px; */
}

.blog-card:hover > div > div img {
    width: 100%;
    transition: 0.5s all ease-in-out;
}

.blog-card:hover > div > div img {
    transform: scale(1.05);
}

/* .ant-pagination .ant-pagination-item > a {
    color: white;
} */

/* :where(.css-dev-only-do-not-override-rodwq0).ant-pagination .ant-pagination-item-active {
    color: black;
    background-color: rgb(72, 72, 72);
} */
/* 
.ant-pagination-prev > button > span {
    color: white;
    padding: 4px;
    border-radius: 4px;
}

.ant-pagination-disabled > button > span {
    color: rgb(72, 72, 72);
    padding: 4px;
    border-radius: 4px;
}

.ant-pagination-next > button > span {
    color: white;
    padding: 4px;
    border-radius: 4px;
}

.ant-pagination-disabled > button > span {
    color: rgb(72, 72, 72);
    padding: 4px;
    border-radius: 4px;
} */
